import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InspectorComponent } from './_dashboard/inspector/inspector.component';
import { BrandingComponent } from './_dashboard/branding/branding.component';
import { ConfigurationComponent } from './_dashboard/configuration/configuration.component';
import { ContentComponent } from './_dashboard/content/content.component';
import { QuestionsComponent } from './_dashboard/questions/questions.component';
import { CostsComponent } from './_dashboard/costs/costs.component';
import { PackagingComponent } from './_dashboard/packaging/packaging.component';
import { PopulationsComponent } from './_dashboard/populations/populations.component';
import { TablesComponent } from './_dashboard/tables/tables.component';
import { FormulasComponent } from './_dashboard/formulas/formulas.component';
import { SuperadminComponent } from './_dashboard/superadmin/superadmin.component';
import { AuthGuard } from './_services/auth.guard';
import { HomeComponent } from './_dashboard/home/home.component';
import { QuestionsAdminComponent } from './_dashboard/questions-admin/questions-admin.component';
import { DashboardComponent } from './_dashboard/dashboard/dashboard.component';
import { SessionsComponent } from './_dashboard/sessions/sessions.component';
import { ApiErrorsComponent } from './_dashboard/api-errors/api-errors.component';
import { SchoolsComponent } from './schools/schools.component';
import { externalLoginGuard } from '_services/external-login.guard';


const routes: Routes = [
    { path: '', canActivate: [externalLoginGuard] },
    { path: 'admin-login', component: HomeComponent },
    { path: 'schools', component: SchoolsComponent, canActivate: [AuthGuard, externalLoginGuard] },
    {
        path: ':schoolId',
        children: [
            { path: 'sessions', component: SessionsComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'inspector', component: InspectorComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'branding', component: BrandingComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'content', component: ContentComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'costs', component: CostsComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'packaging', component: PackagingComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'populations', component: PopulationsComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'tables', component: TablesComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'formulas', component: FormulasComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'superadmin', component: SuperadminComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'questions-admin', component: QuestionsAdminComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: 'api-errors', component: ApiErrorsComponent, canActivate: [AuthGuard, externalLoginGuard] },
            { path: '**', redirectTo: '/' }
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
