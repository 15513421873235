import { inject } from '@angular/core';
import { EplService } from './epl.service';
import { AuthService } from './auth.service';
import { delay, map, take } from 'rxjs';

export function externalLoginGuard() {
  const eplService = inject(EplService);
  const auth = inject(AuthService);

  return auth.user.pipe(
    take(1),
    delay(30000),
    map(user => {
      console.log('Waited 30 seconds');
      if (user && user.admin) {
        return true;
      }
      eplService.initiateEpl();
      return true;
    })
  );
} 