import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, first, map, take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '../_models/user';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { EPLParsedAuthToken } from './epl.service';

@Injectable()
export class AuthService {

  user: Observable<User>;
  noUser = new BehaviorSubject(false);

  constructor(
    private firebaseAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private router: Router) {
    this.user = firebaseAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          if (user.isAnonymous) {
            // logs out a user that was cached from the student application
            this.logout();
          }
          this.noUser.next(false);
          return this.afs.doc<User>(`admin_users/${user.uid}`).valueChanges();
        } else {
          this.noUser.next(true);
          return of(null);
        }
      })
    );
  }

  currentUser(): Promise<User> {
    return this.user.pipe(first()).toPromise();
  }

  signup(email: string, password: string): Promise<void> {
    return this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(newUser => {
        return this.createUserDoc(newUser.user);
      });
  }

  // 1. Enforce admin false
  // 2. Enforce schools null

  createUserDoc(aUser) {
    const userRef = this.afs.doc(`admin_users/${aUser.uid}`);
    const data: User = {
      uid: aUser.uid,
      name: aUser.email,
      email: aUser.email,
      schools: null,
      admin: false
    };
    return userRef.set(data);
  }

  login(email: string, password: string) {
    this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        console.log('User logged in successfully');
      })
      .catch(err => {
        console.log('Auth server says:', err.message);
        alert(err.message);
      });
  }

  logout(reroute: boolean = true): Promise<void> {
    this.noUser.next(true);
    // Could get user doc and determine if admin or not to change redirect after signout
    return this.firebaseAuth
      .signOut()
      .then((status) => {
        if (reroute) {
          // navigate to college board on logout for them to handle auth of user
          window.location.href = 'https://cbaccount.collegeboard.org/professional/enroll?prgCode=NPCAdmin&appId=488';
          return;
        }
      });
  }

  resetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  async eplLogin(eplToken: EPLParsedAuthToken) {
    console.log('EPL Login: ', eplToken);
    const callable = this.fns.httpsCallable('adminCustomToken');
    callable(eplToken).pipe(
      take(1),
      map(async tokenData => {
        console.log(tokenData);
        const { customToken, schoolId } = tokenData;

        await this.firebaseAuth.signInWithCustomToken(customToken);
        // Set persistence to session to logout user on page/tab closed. HostListener in app.component.ts handles on navigating away but not closing tab
        this.firebaseAuth.setPersistence('session');
        this.router.navigate([schoolId + '/dashboard']);
      }),
      catchError(error => {
        // TODO: do something with error
        console.error('Error during EPL login:', error);
        return of(null);
      })
    ).subscribe();
  }

}

// Code from: https://alligator.io/angular/firebase-authentication-@angular/fire//
