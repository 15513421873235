@if (router.url === '/admin-login' && authService.noUser | async) {
  <app-userform></app-userform>
  <router-outlet></router-outlet>
} @else if (authService.noUser | async) {
  <div class="spinner-container">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
} @else {
  <div *ngIf="(authService.user | async) && !(authService.noUser | async)" class="wrapper">
    <app-header></app-header>
    <section class="content">
      <app-navmenu></app-navmenu>
      <main id="content" class="panel" tabindex="-1">
        <router-outlet></router-outlet>
      </main>
    </section>
  </div>
}
