<div style="margin-left: -180px">
    <div class="title-bar">
        <h1>Admin Portal</h1>
        <button mat-button (click)="epl.isAuthorized()">Check auth</button>
    </div>


    <div *ngIf="(auth.user | async) as user; else loading">
        <div *ngIf="user.admin">

            <p>Setting up a new school? Use the
                <a [routerLink]="['../default/superadmin']">Default School</a>
            </p>
            <mat-tab-group mat-stretch-tabs="false">
                <div *ngIf="(sa.liveSchools | async) as liveSchools">
                    <mat-tab [label]="'Live Schools (' + liveSchools.length +')'">

                        <table matSort matSortDisableClear="true" (matSortChange)="sa.sortSchools($event, liveSchools)" class="table table-sm table-hover" style="width:auto;">
                            <thead>
                                <th mat-sort-header="name">School</th>
                                <th mat-sort-header="method">Method</th>
                                <th mat-sort-header="live">Live since</th>
                                <th mat-sort-header="lastLogIn">Last Log In</th>
                                <th mat-sort-header="lastPublished">Last Published</th>
                                <th mat-sort-header="aidYear">Aid Year</th>
                                <th mat-sort-header="visitors">Visitors</th>
                                <th mat-sort-header="results">Results</th>
                            </thead>

                            <tr *ngFor="let school of liveSchools">
                                <td>
                                    <a [routerLink]="['../' + school.id + '/dashboard']">{{ school.name }}</a>
                                </td>
                                <td> {{ school.schoolProperties?.institutionalMethod ? 'IM' : '' }}</td>
                                <td> {{ !school.live ? '' : school.live | dateAgo}}</td>
                                <td> {{ !school.lastLogIn ? '' : school.lastLogIn | dateAgo}} </td>
                                <td> {{ !school.lastPublished ? '' : school.lastPublished | dateAgo}} </td>
                                <td>
                                    <ng-container *ngIf="school.schoolProperties?.draftAidYear as draftAidYear">
                                        <span style="color:darkred">{{ draftAidYear !== school.schoolProperties?.aidYear ? '[' + draftAidYear + ']' : ''}}</span>
                                    </ng-container>
                                    {{ school.schoolProperties?.aidYear }}
                                </td>
                                <td> {{ school.latestTraffic?.visitors }} </td>
                                <td> {{ school.latestTraffic?.results }} </td>
                            </tr>

                        </table>
                        <button mat-button (click)="getCodes(liveSchools)">get codes</button>
                    </mat-tab>

                </div>
                <div *ngIf="(sa.demoSchools | async) as demoSchools">

                    <mat-tab [label]="'Demo Schools (' + demoSchools.length +')'">
                        <table matSort matSortDisableClear="true" (matSortChange)="sa.sortSchools($event, demoSchools)" class="table table-sm table-hover" style="width:auto;">
                            <thead>
                                <th mat-sort-header="name">School</th>
                                <th mat-sort-header="method">Method</th>
                                <th mat-sort-header="lastLogIn">Last Log In</th>
                                <th mat-sort-header="lastPublished">Last Published</th>
                                <th mat-sort-header="aidYear">Aid Year</th>
                            </thead>

                            <tr *ngFor="let school of demoSchools">

                                <td>
                                    <a [routerLink]="['../' + school.id + '/dashboard']">{{ school.name }}</a>
                                </td>
                                <td> {{ school.schoolProperties?.institutionalMethod ? 'IM' : '' }}</td>
                                <td> {{ !school.lastLogIn ? '' : school.lastLogIn | dateAgo}} </td>
                                <td> {{ !school.lastPublished ? '' : school.lastPublished | dateAgo}} </td>
                                <td>
                                    <ng-container *ngIf="school.schoolProperties?.draftAidYear as draftAidYear">
                                        <span style="color:darkred">{{ draftAidYear !== school.schoolProperties?.aidYear ? '[' + draftAidYear + ']' : ''}}</span>
                                    </ng-container>
                                    {{ school.schoolProperties?.aidYear }}
                                </td>
                            </tr>

                        </table>
                        <button mat-button (click)="getCodes(demoSchools)">get codes</button>
                    </mat-tab>
                </div>
                <div *ngIf="(sa.comingSchools | async) as comingSchools">
                    <mat-tab [label]="'Coming Soon (' + comingSchools.length +')'">

                        <table matSort matSortDisableClear="true" (matSortChange)="sa.sortSchools($event, comingSchools)" class="table table-sm table-hover" style="width:auto;">
                            <thead>
                                <th mat-sort-header="name">School</th>
                                <th mat-sort-header="method">Method</th>
                                <th mat-sort-header="lastLogIn">Last Log In</th>
                                <th mat-sort-header="lastPublished">Last Published</th>
                                <th mat-sort-header="aidYear">Aid Year</th>
                            </thead>

                            <tr *ngFor="let school of comingSchools">
                                <td>
                                    <a [routerLink]="['../' + school.id + '/dashboard']">{{ school.name }}</a>
                                </td>
                                <td> {{ school.schoolProperties?.institutionalMethod ? 'IM' : '' }}</td>
                                <td> {{ !school.lastLogIn ? '' : school.lastLogIn | dateAgo}} </td>
                                <td> {{ !school.lastPublished ? '' : school.lastPublished | dateAgo}} </td>
                                <td> {{ school.schoolProperties?.aidYear }} </td>
                            </tr>

                        </table>
                        <button mat-button (click)="getCodes(comingSchools)">get codes</button>
                    </mat-tab>

                </div>

                <div *ngIf="(sa.pausedSchools | async) as pausedSchools">
                    <mat-tab [label]="'Paused Schools (' + pausedSchools.length +')'">

                        <table matSort matSortDisableClear="true" (matSortChange)="sa.sortSchools($event, pausedSchools)" class="table table-sm table-hover" style="width:auto;">
                            <thead>
                                <th mat-sort-header="name">School</th>
                                <th mat-sort-header="method">Method</th>
                                <th mat-sort-header="disabledDate">Disabled since</th>
                                <th mat-sort-header="lastLogIn">Last Log In</th>
                                <th mat-sort-header="lastPublished">Last Published</th>
                                <th mat-sort-header="aidYear">Aid Year</th>
                            </thead>

                            <tr *ngFor="let school of pausedSchools">
                                <td>
                                    <a [routerLink]="['../' + school.id + '/dashboard']">{{ school.name }}</a>
                                </td>
                                <td> {{ school.schoolProperties?.institutionalMethod ? 'IM' : '' }}</td>
                                <td> {{ !school.disabledDate ? '' : school.disabledDate | dateAgo}}</td>
                                <td> {{ !school.lastLogIn ? '' : school.lastLogIn | dateAgo}} </td>
                                <td> {{ !school.lastPublished ? '' : school.lastPublished | dateAgo}} </td>
                                <td> {{ school.schoolProperties?.aidYear }} </td>
                            </tr>

                        </table>
                        <button mat-button (click)="getCodes(pausedSchools)">get codes</button>
                    </mat-tab>

                </div>

                <div *ngIf="(sa.notActiveSchools | async) as disabledSchools">
                    <mat-tab [label]="'Deactivated Schools (' + disabledSchools.length +')'">

                        <table matSort matSortDisableClear="true" (matSortChange)="sa.sortSchools($event, disabledSchools)" class="table table-sm table-hover" style="width:auto;">
                            <thead>
                                <th mat-sort-header="name">School</th>
                                <th mat-sort-header="method">Method</th>
                                <th mat-sort-header="disabledDate">Disabled since</th>
                                <th mat-sort-header="lastLogIn">Last Log In</th>
                                <th mat-sort-header="lastPublished">Last Published</th>
                            </thead>

                            <tr *ngFor="let school of disabledSchools">
                                <td>
                                    <a [routerLink]="['../' + school.id + '/dashboard']">{{ school.name }}</a>
                                </td>
                                <td> {{ school.schoolProperties?.institutionalMethod ? 'IM' : '' }}</td>
                                <td> {{ !school.disabledDate ? '' : school.disabledDate| dateAgo}}</td>
                                <td> {{ !school.lastLogIn ? '' : school.lastLogIn | dateAgo}} </td>
                                <td> {{ !school.lastPublished ? '' : school.lastPublished | dateAgo}} </td>
                            </tr>

                        </table>
                        <button mat-button (click)="getCodes(disabledSchools)">get codes</button>
                    </mat-tab>

                </div>
            </mat-tab-group>

            <p></p>
        </div>

        <!-- Not a Super Admin -->
        <div *ngIf="!user.admin">
            <div *ngIf="(userSchools | async) as schools">
                <div *ngIf="schools.length === 0">
                    <br>
                    <h1 style="padding-left: 20px;">Awaiting access...</h1>
                    <p style="padding-left: 20px;">Please wait for the net price calculator admin staff to authorize your account.
                        Thank you for your patience.</p>
                    <p style="padding-left: 20px;">In a hurry? Call use Toll Free <a href="tel:8006573013">(800)657-3013</a> or
                        send us an email at <a href="mailto:npc_support@rewiretech.com">npc_support&#64;rewiretech.com</a></p>
                </div>

                <div *ngIf="schools.length > 0">
                    <p>Welcome! You have access to multiple schools. Click on your school below to try the options setting.</p>
                    <h2>Available Schools</h2>
                    <div *ngFor="let schoolId of userSchools | async">
                        <a [routerLink]="['../' + schoolId + '/dashboard']">{{ schoolId }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loading>
        Loading stuff...
    </ng-template>

</div>